// @ts-nocheck
// Styles
import './GLabel.sass'

// Extensions
import VLabel from '../../../components/VLabel/VLabel'

// Utilities
import mixins from '../../../util/mixins'
import { VNode } from 'vue'

// Types
const baseMixins = mixins<InstanceType<typeof VLabel>>(VLabel)

export default baseMixins.extend({
  name: 'g-label',

  props: {
    required: Boolean,
    disabled: Boolean,
    value: Boolean,
  },

  render (h, ctx): VNode {
    const capture = (name, data, children) => ({
      name,
      data,
      children,
    })

    // TODO: This should be resolved in another way
    // The workaround is to prevent the color been overwritten by the general theme
    const { props: { color } } = ctx
    ctx.props.color = undefined

    const { name, data, children } = VLabel.options.render(capture, ctx)

    const {
      props: { required, disabled, value },
    } = ctx

    data.class = {
      'g-label': true,
      'g-label--required': required,
      'g-label--is-disabled': disabled,
      'g-label--active': value,
      [`g-label--status--${color}`]: !!color,
      ...data.class,
    }

    return h(name, data, children)
  },
})
